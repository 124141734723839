<template>
	<el-card class="to-main" :style="{'margin-top':marginTop,}">
		<div class="flex between">
			<h1 class="textLeft to-title">{{title}}
				<span class="to-title-span" v-if="orderType==11010">{{dataDetail.applyDate}} {{dataDetail.supplierName}}</span>
				<span class="to-title-span" v-if="orderType==11011">{{dataDetail.newFromDate}} {{dataDetail.newFromTime}} {{dataDetail.supplierName}}</span>
			</h1>
			<div class="to-status">
				<span >订单状态：</span>
				<span class='colorzs'  v-if="orderType==11010">{{dataDetail.refundStatusDesc}}</span>
				<span class='colorzs'  v-if="orderType==11011">{{dataDetail.changeStatusName}}</span>
			</div>
		</div>
		<!-- 退票订单 -->
		<div class="setContent" v-if="orderType==11010">
			<div>退票订单：{{dataDetail.orderNo}}</div>
			<div>服务商：{{dataDetail.supplierName}}</div>
			<div>退票原因：{{dataDetail.refundReasonVip}}</div>
		</div>
		<!-- 改签订单 -->
		<div class="setContent" v-if="orderType==11011">
			<div>改签订单：{{dataDetail.changeNo}}</div>
			<div>服务商：{{dataDetail.supplierName}}</div>
			<div>改签原因：{{dataDetail.changeReasonVip}}</div>
		</div>
		
	</el-card>
</template>

<script> 
	export default{
		name: 'TypeOrder',
		props: {
			dataDetail:{
				type: Object,
				default() {
					return {}
				}
			},
			marginTop:{
				type: String,
				default: '20px'
			},
			title:{
				type: String,
				default: ''
			},
			orderType: {
				type: Number,
				default: 11010,
			}
		},
		data() {
			return {
				
			}
		},
	}
</script>

<style scoped lang="scss">
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px ;
	}
	.to-main{
		font-family: '微软雅黑';
		.to-title{
			font-size: 24px;
			font-weight: 600;
			color: #424242;
			line-height: 24px;
			.to-title-span{
				font-size: 15px;
				font-weight: 400;
				color: #808080;
				padding-left: 20px;
				line-height: 24px;
			}
			
		}
		.to-status{
			font-size: 18px;
			font-weight: 400;
			color: #808080;
			padding-top: 2px;
			line-height: 24px;
		}
		.setContent{
			margin-top: 29px;
			border-top: 1px solid #E6E6E6;
			font-size: 15px;
			font-weight: 400;
			color: #424242;
			text-align: left;
			div{
				padding-top: 22px;
			}
		}
		/deep/ .el-card__body{
			padding: 30px;
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.relative{
			position: relative;
		}
		.absolute{
			position: absolute;
		}
		.font15{
			font-size: 15px;
		}
		.font18{
			font-size: 18px;
		}
		.normal{
			font-weight: normal;
		}
		.bold{
			font-weight: bold;
		}
		.textLeft{
			text-align: left;
		}
		.colorhs{
			color: #808080;
		}
		.colorzs{
			color: #FF8400;
		}
		.line-h30{
			line-height: 30px;
		}
		.borderT{
			border-top: 1px solid #E6E6E6;
		}
		.padT20{
			padding-top: 20px;
		}
		.marT20{
			margin-top: 20px;
		}
	}
</style>
