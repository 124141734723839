<template>
	<el-card class="ci-main" :style="{'margin-top':marginTop,}">
		
		<div class="flex">
			<h1 class="textLeft ci-title">{{title}}</h1>
			<span class="ci-span inBlock" v-if="dataDetail.changeStatus!=10">
				{{dataDetail.changeStatusName}}
			</span>
			<span v-else class="ci-span inBlock">
				<el-tooltip class="item" effect="dark" :content="dataDetail.changeReasonVip" placement="top-start">
				    <span>{{dataDetail.changeStatusName}}</span>
				</el-tooltip>
			</span>
			
		</div>
		<div class="setContent flex">
			<div class="borderR line-h40" style="width: 165px;">{{dataDetail.newTrainCode}} ({{dataDetail.newSeatTypeName}})</div>
			<div class="borderR line-h40" style="width: 180px;">{{dataDetail.newFromDate}} {{dataDetail.newFromWeek}}</div>
			<div class="borderR flex" style="width: 530px;" >
				<div style="width: 189px;" >
					<p class="bold">{{dataDetail.newFromTime}}</p>
					<p>{{dataDetail.newFromStationName}}</p>
				</div>
				<div style="width: 150px;" class="relative">
					<p>{{dataDetail.newTravelTimeStr}}</p>
					<p class="otgi-jt iconfont yj-icon-jiantou1"></p>
				</div>
				<div style="width: 189px;">
					<p class="bold">{{dataDetail.newToTime}}</p>
					<p>{{dataDetail.newToStationName}}</p>
				</div>
			</div>
			<div class="line-h40" style="width: 240px;">
				订单合计: {{dataDetail.newTicketPrice}} (新票价¥{{newPrice}})
			</div>
		</div>
		<div class="flex between marT20">
			
			<span style="line-height: 36px;">乘车人：{{dataDetail.passengerNames}}</span>
			
			<el-button size="medium" type="warning" v-if="dataDetail.showRefundButton==1" @click="goToTrRefund(dataDetail)">退 订</el-button>
		</div>
	</el-card>
</template>

<script>
	export default{
		name: 'ChangeInfo',
		props: {
			dataDetail:{
				type: Object,
				default() {
					return {}
				}
			},
			marginTop:{
				type: String,
				default: '20px'
			},
			title:{
				type: String,
				default: ''
			},
			newPrice:{
				type: [String,Number],
				default: ''
			}
		},
		data() {
			return {
				visible: false,
			}
		},
		methods:{
			//改签状态:1.待送审2.审批中3.订座中4.待支付5.办理中6.已改签7.已取消8.审批拒绝9.取消中,10.订座失败 ,
			// 去退票页
			goToTrRefund(detail){
				if (detail.productType == 2) {
					let refundMsg = '当前订单暂无法提交，请联系空港嘉华客服热线：010-64581922协助处理！'
					 this.$alert(refundMsg, '温馨提示', {
					        confirmButtonText: '确定',
					        callback: action => {
					            
					    }
					});
					
					return
				}
				let path = this.$dict.orderRefundConfig(11011)
				let obj = {
					orderId: detail.changeId,
					applyType: 1
				}
				this.$router.push({path: path,query:obj})

			}
		}
	}
</script>

<style scoped lang="scss">
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px;
	}
	.ci-main{
		font-family: '微软雅黑';
		.ci-title{
			font-size: 24px;
			font-weight: 600;
			color: #424242;
			line-height: 20px;
		}
		.ci-span{
			font-size: 14px;
			font-weight: 600;
			line-height: 24px;
			padding: 0px 10px;
			background-color: #006BB9;
			color: #FFFFFF;
			border-radius: 2px;
			display: inline-block;
			box-sizing: border-box;
			margin-left: 10px;
			position: relative;
			cursor: pointer;
		}
		.ci-span:before{
			content: '';
			position: absolute;
			left: -10px;
			top: 50%;
			transform: translate(0,-50%);
			border-bottom: 5px solid transparent;
			border-top: 5px solid transparent;
			border-left: 5px solid transparent;
			border-right: 5px solid #006BB9;
			width: 0px;
			height: 0px;
		}
		.setContent{
			height: 80px;
			padding: 20px 0;
			margin-top: 27px;
			background: #F3F5F8;
			border-radius: 5px;
			box-sizing: border-box;
			
		}
		/deep/ .el-card__body{
			padding: 30px;
		}
		.flex{
			display: flex;
		}
		/deep/ .el-dialog{
			width: 700px;
		}
		.between{
			justify-content: space-between;
		}
		.h80{
			height: 80px;
		}
		.bg{
			background-color: #F3F5F8;
		}
		.relative{
			position: relative;
		}
		.absolute{
			position: absolute;
		}
		.left{
			left: 0;
		}
		.bottom{
			bottom: 15px;
		}
		.normal{
			font-weight: normal;
		}
		.bold{
			font-weight: bold;
		}
		.textLeft{
			text-align: left;
		}
		.textRight{
			text-align: right;
		}
		.color{
			color: #006BB9;
		}
		.line-h40{
			line-height: 40px;
		}
		.borderR{
			border-right: 1px solid #D1DBE7 ;
		}
		.padTB20{
			padding: 20px 0;
		}
		.marT20{
			margin-top: 20px;
		}
		.box{
			box-sizing: border-box;
		}
		.pointer{
			cursor: pointer;
		}
		.otgi-jt{
			color: #D1DBE7;
			font-size: 20px;
		}
	}
</style>
