<template>
	<el-card class="pi-main" :style="{'margin-top':marginTop,}">
		<h1 class="textLeft pi-title">{{title}}</h1>
		<div class="setContent border box" v-if="orderType==11010">
			<div class="flex set-table-header" >
				<span class="bfb-w10">姓名</span>
				<span class="bfb-w20">证件号</span>
				<span class="bfb-w10">座位</span>
				<span class="bfb-w10">票价(¥)</span>
				<span class="bfb-w10">退票费率(%)</span>
				<span class="bfb-w10">退票手续费(¥)</span>
				<span class="bfb-w10">加收服务费(¥)</span>
				<span class="bfb-w10">退服务费(¥)</span>
				<span class="bfb-w10">应退小计(¥)</span>
			</div>
			<div class="flex set-table-content ">
				<span class="bfb-w10">{{dataDetail.passengerName}}</span>
				<span class="bfb-w20">{{dataDetail.idCode}}</span>
				<span class="bfb-w10">{{dataDetail.seatTypeName}}</span>
				<span class="bfb-w10">{{-dataDetail.ticketPrice}}</span>
				<span class="bfb-w10">{{(dataDetail.cuRefundRate*100)}}%</span>
				<span class="bfb-w10">{{dataDetail.cuRefundPoundage}}</span>
				<span class="bfb-w10">{{dataDetail.sellServiceCharge}}</span>
				<span class="bfb-w10">{{dataDetail.refundServiceCharge}}</span>
				<span class="bfb-w10">{{dataDetail.cuRefundAmount}}</span>
			</div>
			<div class="flex set-table-footer ">
				<span class="bfb-w80"></span>
				<span class="bfb-w10">应退合计</span>
				<span class="bfb-w10 colorZS bold" style="font-size: 16px;">{{dataDetail.cuRefundAmount}}</span>
			</div>
		</div>
		<div v-if="orderType==11011" class="setContent border box">
			<div class="flex set-table-header" >
				<span class="bfb-w10">姓名</span>
				<span class="bfb-w20">证件号</span>
				<span class="bfb-w10">座位</span>
				<span class="bfb-w10">新票价(¥)</span>
				<span class="bfb-w10">旧票价(¥)</span>
				<span class="bfb-w10">差价(¥)</span>
				<span class="bfb-w10">改签手续费(¥)</span>
				<span class="bfb-w10">服务费(¥)</span>
				<span class="bfb-w10">小计(¥)</span>
			</div>
			<div class="flex set-table-content"  v-for="item in dataDetail.psgList" :key="item.idCode">
				<span class="bfb-w10">{{item.passengerName}}</span>
				<span class="bfb-w20">{{item.idCode}}</span>
				<span class="bfb-w10">{{item.newSeatCode}}</span>
				<span class="bfb-w10">{{item.newTicketPrice}}</span>
				<span class="bfb-w10">{{item.oldTicketPrice}}</span>
				<span class="bfb-w10">{{item.priceDiff}}</span>
				<span class="bfb-w10">{{item.buyChangePoundage}}</span>
				<span class="bfb-w10">{{item.buyServiceCharge}}</span>
				<span class="bfb-w10">{{item.changeAmount}}</span>
			</div>
			<div class="flex set-table-footer">
				<span class="bfb-w80"></span>
				<span class="bfb-w10">订单合计</span>
				<span class="bfb-w10 colorZS bold"  style="font-size: 16px;">{{dataDetail.changeAmount}}</span>
			</div>
		</div>
	</el-card>
</template>

<script>
	export default{
		name: 'PassengerInfo',
		props: {
			dataDetail:{
				type: Object,
				default() {
					return {}
				}
			},
			marginTop:{
				type: String,
				default: '20px'
			},
			title:{
				type: String,
				default: ''
			},
			orderType: {
				type: Number,
				default: 11010,
			}
		},
		data() {
			return {
				
			}
		},
		
		
	}
</script>

<style scoped lang="scss">
	 /* bfb-w  表示百分比宽度*/
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px ;
	}
	.pi-main{
		font-family: '微软雅黑';
		.pi-title{
			font-size: 24px;
			font-weight: 600;
			color: #424242;
			line-height: 24px;
		}
		.setContent{
			margin: 38px 0 16px 0;
			box-sizing: border-box;
			.set-table-header{
				height: 45px;
				line-height: 45px;
				background: #F5F5F5;
				font-size: 12px;
				font-weight: 400;
				color: #808080;
			}
			.set-table-content{
				height: 45px;
				line-height: 45px;
				font-size: 12px;
				font-weight: 400;
				color: #424242;
			}
			.set-table-footer{
				height: 45px;
				background: #F5F5F5;
				line-height: 45px;
				font-size: 12px;
				font-weight: 400;
				color: #808080;
			}
		}
		/deep/ .el-card__body{
			padding: 30px;
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.alignItem{
			align-items: center;
		}
		.textline{
			p{
				line-height: 24px;
			}
		}
		.h80{
			height: 80px;
		}
		.h45{
			height: 45px;
			line-height: 45px;
		}
		.line-40{
			height: 40px;
		}
		.bgF5{
			background-color: #F5F5F5;
		}
		.bg{
			background-color: #F3F5F8;
		}
		.relative{
			position: relative;
		}
		.absolute{
			position: absolute;
		}
		.left{
			left: 0;
		}
		.bottom{
			bottom: 15px;
		}
		.border{
			border: 1px solid #CCCCCC;
		}
		.borderT{
			border-top: 1px solid #CCCCCC;
		}
		.borderLR{
			border-left: 1px solid #CCCCCC;
			border-right: 1px solid #CCCCCC;
		}
		.font15{
			font-size: 15px;
		}
		.font16{
			font-size: 16px;
		}
		.normal{
			font-weight: normal;
		}
		.bold{
			font-weight: bold;
		}
		.textLeft{
			text-align: left;
		}
		.colorhs{
			color: #808080;
		}
		.color{
			color: #006BB9;
		}
		.colorZS{
			color: #FF8400;
		}
		.line-h30{
			line-height: 30px;
		}
		.line-h40{
			line-height: 40px;
		}
		.borderR{
			border-right: 1px solid #D1DBE7 ;
		}
		.padTB20{
			padding: 20px 0;
		}
		.marT20{
			margin-top: 20px;
		}
		.pointer{
			cursor: pointer;
		}
		.otgi-jt{
			height: 1px;
			background-color: #D1DBE7;
			width: 100%;
		}
		.bfb-w10{
			width: 10%;
		}
		.bfb-w20{
			width: 20%;
		}
		.bfb-w80{
			width: 80%;
		}
	}
</style>

